<template>
  <v-app>
    <div>
      <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{
          'alert-error': alertType == 'error',
          'alert-success': alertType == 'success'
        }"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
          <h1>Vacation Recall</h1>
        </div>
        <v-form ref="leaveRequest" v-model="valid">
          <v-row no-gutters>
            <v-col :class="personnel.length > 0 ? 'mb-6': 'mb-6'">
              <v-btn :disabled="data.length > 0" class="btn-style justify-start text-style" @click.prevent="perSearch = true"  width="100%" height="38" outlined >  <v-icon left>mdi-magnify</v-icon> {{formData.personnelName == "" ? 'Personnel Name' : formData.personnelName}}</v-btn>
              <!-- <v-btn :disabled="data.length > 0" class="btn-style justify-start text-style" @click.prevent="openPersonnelSearchModal"  width="100%" height="38" outlined >  <v-icon left>mdi-magnify</v-icon> {{formData.personnelName == "" ? 'Personnel Name' : formData.personnelName}}</v-btn> -->
            </v-col>

            <v-col>
              <TextField
                v-model="formData.comment"
                :label="'Comment'"
              />
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
    <v-row>
      <div class="col-md-10 col-sm-12 ml-auto mr-auto">
        <v-col v-if="request.length > 0" class="mb-6">
        <v-data-table :headers="requestHeader" :items="request">
          <template v-slot:item.comments="{ item }">
            <input type="text" class="input" v-model="item.comment" />
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small @click="removeRequest(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
      </div>
    </v-row>

    <!-- Personnel Search Modal -->
    <!-- <Dialog ref="personnelSearch" :title="'Personnel Search'" :width="600">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            outlined
            label="Search"
            @blur="personnelSearchFunction"
            dense
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            dense
            v-model="personnel"
            item-key="id"
            @click:row="selectPersonnel"
            :headers="searchHeaders"
            :items="personnelSearch"
            class="elevation-1 row-pointer"
            :hide-default-footer="true"
          ></v-data-table>
        </v-col>
      </v-row>
      <template v-slot:footer>
        <Button
          :label="'Ok'"
          :btnType="'Cancel'"
          @onClick="closePersonnelSearchModal"
        />
      </template>
    </Dialog> -->

      <v-row v-if="leaveRequests.length > 0">
        <!-- <v-col cols="10" class="">
          <h5 class="ml-auto">Select the days to recall</h5>
        </v-col> -->
        <v-col cols="10" class="mr-auto ml-auto">
           <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Leave Type</th>
                            <th v-for="item in leaveHeaders" :key="item.headerName">{{item.headerName}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="leave in leaveRequests" :key="leave.name">
                            <td>
                              {{leave.name}}
                            </td>
                            <td
                              v-for="(item, index) in leave.leaveData"
                              :key="item.sno"
                              >
                              <input type="checkbox" v-model="leave.leaveData[index].recallLeave" />
                              <!-- <v-checkbox v-model="leave.leaveData[index].recallLeave" color="primary" /> -->
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-col>
           <v-col cols="11 mt-3" class="d-flex justify-end">
            <Button
              :label="'Submit'"
              :btnType="'Submit'"
              @onClick="onSave"
              :isLoading="isSaving"
              class="mr-4"
            />

          <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />
        </v-col>
      </v-row>
      <PersonnelSearch :isLoading="isSaving" :dialog="perSearch" @selectPer="selectPersonnel"  @close="perSearch = false" />
  </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import { employeeService, requestService } from '@/services'
import moment from 'moment'

export default {
  components: {
    TextField,
    Button,
    PersonnelSearch
  },
  data () {
    return {
      alertMessage: '',
      perSearch: false,
      alert: false,
      alertType: '',
      ItemType: null,
      selected: [],
      search: '',
      valid: false,
      isSaving: false,
      generateBtnLoading: false,
      training: '',
      days: '',
      formData: {
        personnelName: '',
        leaveType: '',
        training: '',
        RequestTypeId: 2,
        startDate: '',
        resumptionDate: '',
        days: 0,
        EmployeId: '',
        comment: ''
      },
      data: [],
      request: [],
      leaveTypes: [],
      personnelSearch: [],
      ppeTypes: [],
      searchHeaders: [
        {
          text: 'FullName',
          value: 'name'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'email',
          value: 'email'
        }
      ],
      selectedPersonnelHeader: [
        {
          text: 'Name',
          value: 'name'
        },
        { text: 'Actions', value: 'actions' }
      ],
      requestHeader: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Leave',
          value: 'leaveType'
        },

        {
          text: 'StartDate',
          value: 'startDate'
        },
        {
          text: 'Resumption Date',
          value: 'resumptionDate'
        },
        { text: 'Comment', value: 'comments' },
        { text: 'Actions', value: 'actions' }
      ],
      dataHeader: [
        {
          text: 'Leave Type',
          value: 'leaveType.name'
        },
        {
          text: 'Leave Days',
          value: 'leaveDays'
        },
        {
          text: 'Start Date',
          value: 'startDate'
        },
        {
          text: 'Resumption Date',
          value: 'resumptionDate'
        },
        {
          text: 'Comment',
          value: 'comment'
        }
      ],
      personnel: [],
      leaveRequests: [],
      leaveHeaders: []
    }
  },
  watch: {
    personnel: function () {
      if (this.personnel.length === 0) {
        this.onReset()
      } else {
        this.formData.personnelName = this.personnel[0].name
      }
    }
  },
  methods: {
    openPersonnelSearchModal () {
      this.$refs.personnelSearch.openModal()
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },

    closePersonnelSearchModal () {
      this.personnelSearch = []
      this.$refs.personnelSearch.closeModal()
    },
    personnelSearchFunction () {
      if (this.search === '') {
        this.personnelSearch = []
        return
      }

      employeeService.searchPersonnel(this.search).then(result => {
        this.personnelSearch = result.data
      })
    },
    selectPersonnel (item) {
      let index = null
      for (let i = 0; i < this.personnel.length; i++) {
        if (this.personnel[i].id === item.id) {
          index = i
          break
        }
      }
      if (index === 0) {
        return
      }

      this.personnel.push({ name: `${item.name}`, id: item.id })
      this.formData.EmployeId = item.id
      requestService.getRecallPending(item.id).then(result => {
        this.leaveRequests = []
        const pendingLeaves = result.data
        const newRequests = []
        if (pendingLeaves.length > 0) {
          const firstLeave = pendingLeaves[0]
          const headers = []
          const leavedays = []

          pendingLeaves.forEach(item => {
            headers.push({
              id: item.request.id,
              sno: Math.floor(Math.random() * 101),
              headerName: moment(item.request.startDate).format('MM-DD-YYYY')
            })

            leavedays.push({
              recallLeave: false,
              id: item.request.id,
              sno: Math.floor(Math.random() * 101)
            })
          })

          newRequests.push({
            leaveData: leavedays,
            name: firstLeave.employeeLeaveType.name
          })

          this.leaveHeaders = headers
        }

        this.leaveRequests = newRequests
      })
      this.search = ''
    },
    startDateSelected () {
      const data = { EmployeeId: this.formData.EmployeId, startDate: this.formData.startDate, days: this.formData.days }

      requestService.getResumptionDate(data).then(res => {
        this.formData.resumptionDate = moment(res.data).format('MM-DD-YYYY')
      }).catch(() => {
        this.showAlertMessage('Failed to load Resumption Date', 'error')
      })
    },
    removePersonnel (item) {
      let index = null
      for (let i = 0; i < this.personnel.length; i++) {
        if (this.personnel[i].id === item.id) {
          index = i
          break
        }
      }
      console.log(index)
      this.personnel.splice(index, 1)
    },
    add () {
      const newDays = parseInt(this.days) - parseInt(this.formData.days)
      console.log('new day', newDays)
      if (newDays < 0) {
        this.showAlertMessage(`you have ${this.days} leave days left`, 'error')
        setTimeout(() => {
          this.alert = false
        }, 5000)
        return
      }
      this.data.push({ leaveDays: this.formData.days, startDate: this.formData.startDate, resumptionDate: this.formData.resumptionDate, comment: this.formData.comment, leaveType: this.formData.leaveType })
      this.days = '' + newDays
      // console.log('days', typeof this.days)
      // console.log('leave days', typeof this.formData.days)
    },
    removeRequest (item) {
      let index = null
      for (let i = 0; i < this.request.length; i++) {
        if (this.request[i].id === item.id) {
          index = i
          break
        }
      }
      console.log(index)
      this.request.splice(index, 1)
    },
    generateRequest () {
      this.generateBtnLoading = true
      const leaveRequesters = []

      this.personnel.forEach(per => {
        leaveRequesters.push({
          EmployeId: per.id,
          RequestTypeId: 2,
          RequesStartDate: this.formData.startDate
        })
      })

      requestService.validateRequests(leaveRequesters).then(result => {
        if (result.data.length === 0) {
          this.personnel.forEach(per => {
            this.request.push({
              name: per.name,
              id: per.id,
              leaveType: this.formData.leaveType.name,
              leaveTypeId: this.formData.leaveType.id,
              startDate: this.formData.startDate,
              LeaveDays: this.formData.days,
              resumptionDate: this.formData.resumptionDate,
              comment: per.comment
            })
          })
        }
      }).catch(error => {
        console.log(error)
      })

      this.generateBtnLoading = false

      console.log(this.request)
    },
    onSave () {
      this.isSaving = true
      const leaveIds = []
      this.leaveRequests.forEach(item => {
        const leaveDays = item.leaveData
        leaveDays.forEach(leave => {
          if (leave.recallLeave === true) {
            leaveIds.push(
              leave.id
            )
          }
        })
      })

      const payload = {
        ids: leaveIds,
        comment: this.formData.comment
      }

      requestService.saveRecall(payload).then(result => {
        this.showAlertMessage('Request successfully recalled', 'success')
        this.onReset()
      }).catch(() => {
        this.isSaving = false
      })

      // this.$store
      //   .dispatch(CREATE_REQUEST, { type: 'leave', data })
      //   .then(() => {
      //     this.showAlertMessage('Request creation successful', 'success')
      //     this.onReset()
      //   })
      //   .catch(error => {
      //     console.log(error)
      //     this.showAlertMessage('Request creation failed', 'error')
      //   })
      //   .finally(() => {
      //     this.isSaving = false
      //     this.data = []
      //   })
    },
    onReset () {
      // this.personnel = []
      this.request = []
      this.data = []
      this.formData.personnelName = ''
      this.formData.leaveType = ''
      this.formData.training = ''
      this.formData.RequestTypeId = 2
      this.formData.startDate = ''
      this.formData.resumptionDate = ''
      this.formData.days = 0
      this.formData.EmployeId = ''
      this.formData.comment = ''
      this.leaveRequests = []
      this.leaveHeaders = []
      this.$refs.leaveRequest.reset()
    }
  },
  mounted () {

  }
}
</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.btn-style {
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
}
.text-style {
  text-transform: capitalize;
}
.input {
  border: 1px solid grey;
  height: 30px;
  padding-left: 10px;
}
</style>
